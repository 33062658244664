<footer>
  <div class="row">
<a href="/">
  <img src="assets/images/logo.png">
</a>
    <ul>
      <li>
        <a href="https://instagram.com/raphaelclinic" target="_blank">
          <svg id="_001-instagram" data-name="001-instagram" xmlns="http://www.w3.org/2000/svg" width="25.503" height="25.508" viewBox="0 0 25.503 25.508">
            <path id="Path_11" data-name="Path 11" d="M25.887,7.5A9.321,9.321,0,0,0,25.294,4.4,6.534,6.534,0,0,0,21.562.673,9.345,9.345,0,0,0,18.467.08C17.1.015,16.668,0,13.205,0s-3.9.015-5.257.075A9.323,9.323,0,0,0,4.854.668,6.224,6.224,0,0,0,2.591,2.143,6.28,6.28,0,0,0,1.121,4.4,9.346,9.346,0,0,0,.528,7.494c-.065,1.365-.08,1.8-.08,5.262s.015,3.9.075,5.257a9.32,9.32,0,0,0,.593,3.095,6.533,6.533,0,0,0,3.732,3.732,9.346,9.346,0,0,0,3.095.593c1.36.06,1.794.075,5.257.075s3.9-.015,5.257-.075a9.318,9.318,0,0,0,3.094-.593,6.524,6.524,0,0,0,3.732-3.732,9.352,9.352,0,0,0,.593-3.095c.06-1.36.075-1.794.075-5.257s-.005-3.9-.065-5.257Zm-2.3,10.415a6.992,6.992,0,0,1-.439,2.367A4.231,4.231,0,0,1,20.729,22.7a7.016,7.016,0,0,1-2.367.438c-1.345.06-1.749.075-5.152.075S9.4,23.2,8.058,23.141A6.988,6.988,0,0,1,5.691,22.7a3.925,3.925,0,0,1-1.465-.952,3.966,3.966,0,0,1-.952-1.465,7.018,7.018,0,0,1-.438-2.367c-.06-1.345-.075-1.749-.075-5.153s.015-3.812.075-5.152a6.988,6.988,0,0,1,.438-2.367,3.877,3.877,0,0,1,.957-1.465A3.96,3.96,0,0,1,5.7,2.83a7.021,7.021,0,0,1,2.367-.439c1.345-.06,1.749-.075,5.152-.075s3.812.015,5.153.075a6.992,6.992,0,0,1,2.367.439,3.922,3.922,0,0,1,1.465.952,3.965,3.965,0,0,1,.952,1.465,7.02,7.02,0,0,1,.439,2.367c.06,1.345.075,1.749.075,5.152s-.015,3.8-.075,5.148Zm0,0" transform="translate(-0.449 0)" fill="#fff"/>
            <path id="Path_12" data-name="Path 12" d="M131.5,124.5a6.553,6.553,0,1,0,6.553,6.553A6.554,6.554,0,0,0,131.5,124.5Zm0,10.8a4.251,4.251,0,1,1,4.251-4.251A4.251,4.251,0,0,1,131.5,135.3Zm0,0" transform="translate(-118.745 -118.296)" fill="#fff"/>
            <path id="Path_13" data-name="Path 13" d="M365.509,90.132a1.53,1.53,0,1,1-1.53-1.53,1.53,1.53,0,0,1,1.53,1.53Zm0,0" transform="translate(-344.411 -84.187)" fill="#fff"/>
          </svg>

        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/RaphaelClinics/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.013" height="26.013" viewBox="0 0 26.013 26.013">
            <path id="_002-facebook" data-name="002-facebook" d="M3.737,25.513h8.322V16.444H9.069v-2.99h2.99V9.717A3.741,3.741,0,0,1,15.8,5.98h3.737v2.99h-2.99a1.5,1.5,0,0,0-1.495,1.495v2.99H19.4l-.5,2.99H15.049v9.069h6.727a3.741,3.741,0,0,0,3.737-3.737V3.737A3.741,3.741,0,0,0,21.776,0H3.737A3.741,3.741,0,0,0,0,3.737V21.776A3.741,3.741,0,0,0,3.737,25.513ZM1.495,3.737A2.245,2.245,0,0,1,3.737,1.495H21.776a2.245,2.245,0,0,1,2.242,2.242V21.776a2.245,2.245,0,0,1-2.242,2.242H16.544V17.939h3.623l1-5.98h-4.62V10.464h4.485V4.485H15.8a5.238,5.238,0,0,0-5.232,5.232v2.242H7.574v5.98h2.99v6.079H3.737a2.245,2.245,0,0,1-2.242-2.242Zm0,0" transform="translate(0.25 0.25)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
          </svg>

        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCPEpmIOKC952gJA8_6pCOYQ" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.013" height="26.013"  x="0px" y="0px" viewBox="0 0 500.013 500.013" style="enable-background:new 0 0 500.013 500.013;" xml:space="preserve"> 
            <path style="fill:#f5f8f5;" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> 
          </svg>
        </a>
      </li>
    </ul>
  </div>
  <div class="info">
    <div class="item">
      <i><svg xmlns="http://www.w3.org/2000/svg" width="15.643" height="18.493" viewBox="0 0 15.643 18.493">
        <g id="location_3_" data-name="location (3)" opacity="0.47">
          <g id="Group_22" data-name="Group 22" transform="translate(0)">
            <g id="Group_21" data-name="Group 21">
              <path id="Path_14" data-name="Path 14" d="M52.8,2.291A7.821,7.821,0,0,0,39.451,7.821c0,4.226,4,7.742,6.143,9.63.3.262.556.489.761.681a1.348,1.348,0,0,0,1.835,0c.205-.192.463-.418.761-.681,2.147-1.889,6.143-5.4,6.143-9.63A7.771,7.771,0,0,0,52.8,2.291ZM48.235,16.638c-.3.268-.568.5-.785.7a.261.261,0,0,1-.356,0c-.217-.2-.48-.434-.785-.7-2.018-1.775-5.775-5.08-5.775-8.817a6.738,6.738,0,0,1,13.476,0C54.011,11.558,50.254,14.863,48.235,16.638Z" transform="translate(-39.451)" fill="#fff"/>
            </g>
          </g>
          <g id="Group_24" data-name="Group 24" transform="translate(4.375 4.079)">
            <g id="Group_23" data-name="Group 23">
              <path id="Path_15" data-name="Path 15" d="M164.017,112.938a3.447,3.447,0,1,0,3.447,3.447A3.451,3.451,0,0,0,164.017,112.938Zm0,5.81a2.364,2.364,0,1,1,2.364-2.364A2.366,2.366,0,0,1,164.017,118.748Z" transform="translate(-160.57 -112.938)" fill="#fff"/>
            </g>
          </g>
        </g>
      </svg>
      </i>
      <span>9A El Annab St, From Elbatal Ahmed
        abd El Aziz st, Mohandessin</span>
    </div>
    <div class="item"><i><svg xmlns="http://www.w3.org/2000/svg" width="18.22" height="18.289" viewBox="0 0 18.22 18.289">
      <g id="telephone" opacity="0.47">
        <path id="Path_16" data-name="Path 16" d="M4.6,12.158a19.4,19.4,0,0,0,6.454,5.056,10.045,10.045,0,0,0,3.612,1.069c.087,0,.171.008.258.008a2.991,2.991,0,0,0,2.319-1,.083.083,0,0,0,.015-.019,9.765,9.765,0,0,1,.731-.758c.178-.171.36-.349.534-.531a1.83,1.83,0,0,0-.008-2.725l-2.278-2.278a1.844,1.844,0,0,0-1.334-.614,1.906,1.906,0,0,0-1.349.61L12.2,12.336c-.125-.072-.254-.136-.375-.2a4.686,4.686,0,0,1-.417-.227,14.359,14.359,0,0,1-3.43-3.123,8.269,8.269,0,0,1-1.16-1.849c.356-.322.69-.659,1.012-.989.114-.117.231-.235.349-.352A1.943,1.943,0,0,0,8.81,4.233a1.928,1.928,0,0,0-.629-1.364L7.052,1.739c-.133-.133-.258-.261-.387-.394C6.415,1.088,6.154.822,5.9.584A1.889,1.889,0,0,0,4.562,0,1.945,1.945,0,0,0,3.213.587L1.8,2A2.912,2.912,0,0,0,.928,3.869,7.014,7.014,0,0,0,1.455,6.9,17.638,17.638,0,0,0,4.6,12.158ZM1.852,3.949a2,2,0,0,1,.6-1.289l1.41-1.41a1.021,1.021,0,0,1,.7-.322.968.968,0,0,1,.682.33c.254.235.493.481.75.743.129.133.261.265.394.4L7.518,3.532a1.029,1.029,0,0,1,.356.709,1.029,1.029,0,0,1-.356.709c-.117.117-.235.239-.352.356C6.813,5.662,6.484,6,6.12,6.321L6.1,6.34a.741.741,0,0,0-.189.841c0,.011.008.019.011.03a8.919,8.919,0,0,0,1.33,2.164A15.155,15.155,0,0,0,10.906,12.7a5.425,5.425,0,0,0,.5.273,4.687,4.687,0,0,1,.417.227l.042.023a.792.792,0,0,0,.368.095.8.8,0,0,0,.565-.258l1.417-1.417a1,1,0,0,1,.694-.337.952.952,0,0,1,.671.337l2.285,2.281a.914.914,0,0,1-.011,1.429c-.159.171-.326.333-.5.5a10.914,10.914,0,0,0-.792.822,2.081,2.081,0,0,1-1.626.69c-.064,0-.133,0-.2-.008a9.126,9.126,0,0,1-3.267-.978,18.433,18.433,0,0,1-6.143-4.813A16.883,16.883,0,0,1,2.33,6.59,6.112,6.112,0,0,1,1.852,3.949Z" transform="translate(-0.912 0)" fill="#fff"/>
      </g>
    </svg>
    </i><span> <a href="tel:+2 0111 299 5524"> +2 0111 299 5524</a></span></div>
  </div>
</footer>
